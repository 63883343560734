import React, { useState, useEffect, useRef } from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core';

import { IconSvg } from '../IconSvg';

const useStyles = makeStyles((theme) => ({
  topHeader: {
    textAlign: 'center',
    // maxWidth: '528px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  topContent: {
    color: '#4B5B69',
    width: '100%',
    textAlign: 'center',
    fontSize: '1.2rem',
    margin: '1rem auto 5rem auto',
    lineHeight: '1.2',
    maxWidth: '1080px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      margin: '1rem auto 2rem auto',
    },
  },
  pinkHighlight: {
    color: '#D10066',
  },
  h3HeaderTop: {
    textAlign: 'left',
    color: '#D10066',
    paddingBottom: '0px',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
    fontFamily: '"Poppins", sans-serif',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  h3Header: {
    color: '#002D5C',
    fontSize: '2.5rem',
    fontWeight: 700,
    textAlign: 'left',
    paddingBottom: '1rem',
    fontFamily: '"Poppins", sans-serif',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.9rem',
    },
  },
  bodyContent: {
    textAlign: 'left',
  },
  cta: {
    marginTop: '20px',
    backgroundColor: '#0380B6',
    color: '#ffffff',
    fontWeight: '600',
    textTransform: 'uppercase',
    padding: '16px 32px',
    '&:hover': {
      backgroundColor: '#002d5c',
    },
  },
  card: {
    marginBottom: '7rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5rem',
    },
    '&:last-child': {
      marginBottom: 0, // Remove margin for the last card
    },
  },
  boxImg: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
  },
  scrollUl: {
    listStyle: 'none',
    padding: 0,
    margin: '1.5rem 0 0 0',
    '& li': {
      marginBottom: '.75rem',
      display: 'flex',
      alignItems: 'center',
    },
  },
  icon: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  imageContainer: {
    position: 'sticky',
    top: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const HomeScroll = ({ intro, features }) => {
  const classes = useStyles();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const sectionsRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionsRefs.current.indexOf(entry.target);
            if (index !== -1) setActiveImageIndex(index);
          }
        });
      },
      {
        root: null,
        threshold: 1, // Adjust this to change how much of the section should be visible to trigger
      }
    );

    sectionsRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sectionsRefs.current.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  const med = useMediaQuery('(max-width: 960px)');

  return (
    <Container>
      {intro && (
        <Grid container justifyContent='center' alignItems='center'>
          <Grid item xs={12} md={7}>
            <PortableText
              content={intro}
              serializers={{
                h2: ({ children }) => (
                  <Typography
                    variant='h2'
                    color='primary'
                    className={classes.topHeader}
                  >
                    {children}
                  </Typography>
                ),
                normal: ({ children }) => (
                  <Typography variant='body1' className={classes.topContent}>
                    {children}
                  </Typography>
                ),
                strong: ({ children }) => (
                  <span className={classes.pinkHighlight}>{children}</span>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        {/* Right: Text Sections */}
        <Grid item xs={12} md={6}>
          {features.map((feature, index) => (
            <div
              key={feature.id || index}
              className={classes.card}
              ref={(el) => (sectionsRefs.current[index] = el)}
            >
              <Typography variant='body1' className={classes.h3HeaderTop}>
                {feature.bodySubHeader}
              </Typography>
              <Typography variant='h2' className={classes.h3Header}>
                {feature.bodyHeader}
              </Typography>
              {med ? (
                <GatsbyImage
                  image={feature.image.featureImage.asset.gatsbyImageData}
                  imgStyle={{ objectFit: 'contain' }}
                  style={{ margin: '2rem 0' }}
                  loading='eager'
                />
              ) : null}
              <PortableText
                content={feature._rawBodyCopy}
                serializers={{
                  normal: ({ children }) => (
                    <Typography variant='body1' className={classes.bodyContent}>
                      {children}
                    </Typography>
                  ),
                  ul: ({ children }) => (
                    <ul className={classes.scrollUl}>
                      {React.Children.map(children, (child, i) =>
                        React.isValidElement(child)
                          ? React.cloneElement(child, {
                              key: i,
                              children: (
                                <>
                                  <span className={classes.icon}>
                                    <IconSvg />
                                  </span>
                                  <Typography
                                    variant='body1'
                                    className={classes.bodyContent}
                                  >
                                    {child.props.children}
                                  </Typography>
                                </>
                              ),
                            })
                          : null
                      )}
                    </ul>
                  ),
                }}
              />
              {feature.ctaText && feature.ctaLink && (
                <Button
                  variant='contained'
                  size='large'
                  className={classes.cta}
                  href={feature.ctaLink}
                  component='a'
                >
                  {feature.ctaText}
                </Button>
              )}
            </div>
          ))}
        </Grid>
        {/* Left: Sticky Image */}
        <Grid item xs={12} md={6} className={classes.imageContainer}>
          {features[activeImageIndex]?.image?.featureImage?.asset
            ?.gatsbyImageData ? (
            <GatsbyImage
              image={
                features[activeImageIndex].image.featureImage.asset
                  .gatsbyImageData
              }
              alt={features[activeImageIndex].title || ''}
              className={classes.boxImg}
              imgStyle={{ objectFit: 'contain' }}
              loading='eager'
              placeholder='none' // Remove blur effect
            />
          ) : (
            <Typography>No image available</Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
