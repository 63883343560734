import React, { useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';

import { useMediaQuery } from '@material-ui/core';

import { HomeHero } from '../components/Home/HomeHero';
import { IntroCards } from '../components/Home/Intro/IntroCards';
import { IntroStats } from '../components/Home/Intro/IntroStats';
import { HomeScroll } from '../components/Home/HomeScroll';
import { ProductLogos } from '../components/Home/ProductLogos';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { SectionLight } from '../components/Sections/SectionLight';
import { HeroWaveOne } from '../components/Waves/HeroWaveOne';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { SEO } from '../components/SEO';

import LocaleContext from '../context/LocaleContext';
import Form from '../components/ContactForm/Form';

const IndexPage = ({ data: { home }, location }) => {
  const { handleRedirect, ipData, setIpData } = useContext(LocaleContext);

  const {
    _rawHomeScrollIntroText,
    homeScrollFeatures,
    homeSpinningImages,
    _rawIntroText,
    _rawCustomerLogosHeader,
    customerLogos,
    testimonialHeader,
    testimonials,
    resourceTitle,
    resources,
    _rawResourceBody,
    hero,
    introCards,
    _rawByTheNumbersHeader,
    byTheNumbers,
    byTheNumbersDisclaimer,
    noStatsCta,
    contactForm,
    pardotUrl,
    metaTitle,
    metaDescription,
  } = home;

  const [homeScrollFeaturesArray, setHomeScrollFeaturesArray] = useState([]);
  const [homeSpinningFeature, setHomeSpinningFeature] = useState({});

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
    //Remove last entry of array. Last one is used for spinning logo feature
    setHomeScrollFeaturesArray(homeScrollFeatures.slice(0, 3));
    // Extract last entry of array. Last one is used for spinning logo feature
    setHomeSpinningFeature(homeScrollFeatures[homeScrollFeatures.length - 1]);
  }, [ipData]);

  const md = useMediaQuery('(max-width: 960px)');
  const video = testimonials.length ? testimonials[0]?.videoVariant : null;
  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <SectionBlue top extraBottom>
        <HomeHero heroData={hero} />
        <HeroWaveOne />
      </SectionBlue>
      <SectionWhite marginBottom='-80px'>
        <IntroCards intro={_rawIntroText} introCards={introCards} />
      </SectionWhite>
      <SectionBlue
        top
        bottom
        additionalBottom={!md ? 120 : 80}
        additionalTop={60}
      >
        <IntroStats
          header={_rawByTheNumbersHeader}
          stats={byTheNumbers}
          disclaimer={byTheNumbersDisclaimer}
          noStatsCta={noStatsCta}
        />
        <SectionWaveDefault
          flipHorizontal={true}
          flipVertical={true}
          bottom='-1px'
          fillColorShape='#FFF'
        />
      </SectionBlue>
      {homeScrollFeaturesArray.length ? (
        <SectionWhite>
          <HomeScroll
            intro={_rawHomeScrollIntroText}
            features={homeScrollFeaturesArray}
          />
        </SectionWhite>
      ) : null}
      {!!homeSpinningFeature ? (
        <SectionLight top extraTop={!md} zIndex={3} bottom={md}>
          <ProductLogos
            spinningImages={homeSpinningImages}
            feature={homeSpinningFeature}
          />
          <SectionWaveDefault
            flipHorizontal={true}
            top='0px'
            fillColorShape='#FFF'
            fillColorBorder='transparent'
          />
        </SectionLight>
      ) : null}
      <SectionBlue
        extraTop={!video}
        top={video}
        additionalTop={video && md ? 60 : video ? 80 : null}
        marginBottom='-220px'
      >
        <SectionWaveDefault top='0px' flipHorizontal={true} />
        <Testimonials
          testimonials={testimonials}
          header={testimonialHeader}
          background
        />
      </SectionBlue>
      {customerLogos && (
        <SectionWhite top additionalTop={220} extraBottom>
          <CustomerLogos
            customerLogosArray={customerLogos}
            customerLogosHeader={_rawCustomerLogosHeader}
          />
          <SectionWaveDefault
            flipHorizontal={false}
            flipVertical={true}
            bottom='0px'
            fillColorShape='#F5F9FF'
            fillColorBorder='#F5F9FF'
          />
        </SectionWhite>
      )}
      <SectionLight top={md} bottom={md}>
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          location={location}
        />
      </SectionLight>
      <SectionWhite top additionalTop={100} additionalBottom={100}>
        <SectionWaveDefault
          top='0px'
          fillColorBorder='#F5F9FF'
          flipHorizontal={true}
        />
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($slug: String!) {
    home: sanityHome(slug: { current: { eq: $slug } }) {
      metaTitle
      metaDescription
      _type
      id
      slug {
        current
      }
      language
      _rawIntroText
      introCards {
        icon
        header
        description
      }
      _rawByTheNumbersHeader
      byTheNumbers {
        number
        unit
        _rawDescription
        icon
      }
      byTheNumbersDisclaimer
      noStatsCta {
        ctaBackground {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaHeader
        _rawCtaBlockHeader
        ctaLink
        ctaSubheader
        ctaText
        internalLink
      }
      _rawHomeScrollIntroText
      homeScrollFeatures {
        _key
        title
        cardTitle
        cardFaIcon
        cardIconColor {
          hexValue
        }
        bodySubHeader
        bodyHeader
        _rawAlternativeBodyHeader
        image {
          featureImage {
            asset {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
        videoVariant
        androidLink
        iosLink
        qbLink
        thumbnailVideoUrl
        ctaText
        ctaLink
        _rawBodyCopy
        internalLink
      }
      homeSpinningImages {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX)
          }
        }
        title
      }
      hero {
        _rawHomeHeroContent(resolveReferences: { maxDepth: 10 })
        homeHeroVideo
        videoImage {
          asset {
            url
          }
        }
      }
      testimonialHeader
      testimonials {
        title
        testimonialText
        nameAndTitle
        company
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        videoVariant
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
    }
  }
`;
