import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heroWaveOne: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    bottom: '-1px',
    position: 'absolute',
    left: '0',
    // Set container height by breakpoints:
    [theme.breakpoints.up('xl')]: {
      width: '100% !important',
      height: 'auto !important',
    },
    [theme.breakpoints.down('xl')]: {
      width: 'auto',
      height: 225,
    },
    [theme.breakpoints.down('lg')]: {
      height: 200,
    },
    [theme.breakpoints.down('md')]: {
      height: 150,
    },
    [theme.breakpoints.down('sm')]: {
      height: 125,
    },
    [theme.breakpoints.down('xs')]: {
      height: 100,
    },
  },
}));

export const HeroWaveOne = () => {
  const classes = useStyles();
  return (
    <svg
      viewBox="0 0 1440 150"
      className={classes.heroWaveOne}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M291 91C121.163 108.366 81.2126 98.8141 1.10524e-05 -0.00012207L0 126L1440 126L1440 0.500003L291 91Z"
        fill="#63CDE5"
      />
      <path
        d="M290.837 103.634C122 117.5 81.2126 112.814 1.11125e-05 13.9999L0 140L1440 140L1440 12.8566L290.837 103.634Z"
        fill="#367EB4"
      />
      <path
        d="M290.837 113.634C116.5 126 81.2126 122.814 1.11125e-05 23.9999L0 150L1440 150L1440 20.5L290.837 113.634Z"
        fill="white"
      />
    </svg>
  );
};
