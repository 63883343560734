import React, { useContext, useEffect, useState } from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Grid, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import FormModalContext from '../../context/FormModalContext';
import { IconSvg } from '../IconSvg';

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'left',
    marginBottom: '1rem',
  },
  blueHighlight: {
    color: '#0380B6',
  },
  paragraph: {
    textAlign: 'left',
    fontSize: '1.2rem',
    marginBottom: '1.5rem',
  },
  customList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: '600',
    color: '#002d5c',
    fontSize: '1.2rem',
    '&:last-child': {},
  },
  scrollUl: {
    listStyle: 'none',
    padding: 0,
    margin: '1.5rem 0 0 0',
    '& li': {
      marginBottom: '.75rem',
      display: 'flex',
      alignItems: 'center',
      fontFamily: '"Poppins", sans-serif',
      fontWeight: '600',
      color: '#002d5c',
      fontSize: '1.2rem',
      '&:last-child': { marginBottom: '0.5rem' },
    },
  },
  icon: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  cta: {
    marginTop: '20px',
    backgroundColor: '#0380B6',
    color: '#ffffff',
    fontWeight: '600',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: '#002d5c',
    },
  },
  bigCircle: {
    width: '400px',
    height: '400px',
    backgroundColor: '#F5F9FF',
    borderRadius: '50%',
    display: 'flex',
    marginLeft: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    transform: 'scale(1.2)',
    zIndex: '3',
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      marginBottom: '1.2rem',
      transform: 'scale(1)',
    },
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.8)',
      width: '350px',
      height: '350px',
    },
  },
  middleCircle: {
    width: '120px',
    height: '120px',
    backgroundColor: '#002d5c',
    boxShadow: '0 0px 10px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  middleCircleImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  smallCircle: {
    width: '75px',
    height: '75px',
    backgroundColor: '#002d5c',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0px 10px 4px rgba(0, 0, 0, 0.15)',
    borderRadius: '50%',
    position: 'absolute',
    transition: 'all 0.5s ease',
    transformOrigin: 'center',
    willChange: 'transform',
    // '&:hover': {
    //   backgroundColor: '#0380B6',
    // },
  },
  smallCircleImg: {
    width: '90%',
    height: '90%',
    objectFit: 'contain',
  },
  smallCircle1: {
    animation: '$circle1 30s linear infinite',
  },
  smallCircle2: {
    animation: '$circle2 30s linear infinite',
  },
  smallCircle3: {
    animation: '$circle3 30s linear infinite',
  },
  smallCircle4: {
    animation: '$circle4 30s linear infinite',
  },
  smallCircle5: {
    animation: '$circle5 30s linear infinite',
  },
  smallCircle6: {
    animation: '$circle6 30s linear infinite',
  },
  smallCircle7: {
    animation: '$circle7 30s linear infinite',
  },
  smallCircle8: {
    animation: '$circle8 30s linear infinite',
  },
  '@keyframes circle1': {
    '0%': { transform: 'rotate(0deg) translate(160px) rotate(0deg)' },
    '100%': { transform: 'rotate(360deg) translate(160px) rotate(-360deg)' },
  },
  '@keyframes circle2': {
    '0%': { transform: 'rotate(72deg) translate(160px) rotate(-72deg)' },
    '100%': { transform: 'rotate(432deg) translate(160px) rotate(-432deg)' },
  },
  '@keyframes circle3': {
    '0%': { transform: 'rotate(144deg) translate(160px) rotate(-144deg)' },
    '100%': { transform: 'rotate(504deg) translate(160px) rotate(-504deg)' },
  },
  '@keyframes circle4': {
    '0%': { transform: 'rotate(216deg) translate(160px) rotate(-216deg)' },
    '100%': { transform: 'rotate(576deg) translate(160px) rotate(-576deg)' },
  },
  '@keyframes circle5': {
    '0%': { transform: 'rotate(288deg) translate(160px) rotate(-288deg)' },
    '100%': { transform: 'rotate(648deg) translate(160px) rotate(-648deg)' },
  },
  '@keyframes circle6': {
    '0%': { transform: 'rotate(0deg) translate(160px) rotate(0deg)' },
    '100%': { transform: 'rotate(360deg) translate(160px) rotate(-360deg)' },
  },
  '@keyframes circle7': {
    '0%': { transform: 'rotate(120deg) translate(160px) rotate(-120deg)' },
    '100%': { transform: 'rotate(504deg) translate(160px) rotate(-504deg)' },
  },
  '@keyframes circle8': {
    '0%': { transform: 'rotate(240deg) translate(160px) rotate(-240deg)' },
    '100%': { transform: 'rotate(648deg) translate(160px) rotate(-648deg)' },
  },
}));

export const ProductLogos = ({ spinningImages, feature }) => {
  const classes = useStyles();
  const { handleModalClick } = useContext(FormModalContext);
  const [finalSpinningImages, setFinalSpinningImages] = useState([]);

  const smallCircleClass = (index, imageCount) => {
    const classesByIndex = [
      classes.smallCircle1,
      classes.smallCircle2,
      classes.smallCircle3,
      classes.smallCircle4,
      classes.smallCircle5,
    ];

    const classesByIndexReduced = [
      classes.smallCircle6,
      classes.smallCircle7,
      classes.smallCircle8,
    ];

    return `${classes.smallCircle} ${
      imageCount < 5 ? classesByIndexReduced[index] : classesByIndex[index]
    }`;
  };

  const fillExtraIconsSpinningImages = (spinningImages) => {
    const filledSpinningImages = spinningImages.slice(1);
    filledSpinningImages.length === 3 &&
      filledSpinningImages.push({ icon: 'vacuum' }, { icon: 'shield-check' });
    filledSpinningImages.length === 4 &&
      filledSpinningImages.push({ icon: 'vacuum' });
    setFinalSpinningImages(filledSpinningImages);
  };

  useEffect(() => {
    fillExtraIconsSpinningImages(spinningImages);
  }, [spinningImages]);

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} container alignItems='center'>
            <div className={classes.bigCircle}>
              <div className={classes.middleCircle}>
                <GatsbyImage
                  image={spinningImages[0]?.asset?.gatsbyImageData}
                  alt='Middle'
                  className={classes.middleCircleImg}
                />
              </div>
              {finalSpinningImages
                .sort(() => Math.random() - 0.5)
                .map((image, index) => (
                  <div
                    key={index}
                    className={smallCircleClass(
                      index,
                      finalSpinningImages.length
                    )}
                  >
                    {image.asset ? (
                      <GatsbyImage
                        image={image?.asset?.gatsbyImageData}
                        alt={`Circle ${index + 1}`}
                        className={classes.smallCircleImg}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={['fas', image.icon ?? 'fish']}
                        style={{
                          width: '60%',
                          height: '60%',
                          color: 'white',
                        }}
                        alt={`Circle ${index + 1}`}
                        className={classes.smallCircleImg}
                      />
                    )}
                  </div>
                ))}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <PortableText
              content={feature._rawAlternativeBodyHeader || []}
              serializers={{
                h2: ({ children }) => (
                  <Typography
                    variant='h2'
                    color='primary'
                    className={classes.header}
                  >
                    {children}
                  </Typography>
                ),
                strong: ({ children }) => (
                  <span className={classes.blueHighlight}>{children}</span>
                ),
              }}
            />
            <PortableText
              content={feature._rawBodyCopy || []}
              serializers={{
                normal: ({ children }) => (
                  <Typography variant='body1' className={classes.paragraph}>
                    {children}
                  </Typography>
                ),
                ul: ({ children }) => {
                  return (
                    <ul className={classes.scrollUl}>
                      {React.Children.map(children, (child, i) =>
                        React.isValidElement(child)
                          ? React.cloneElement(child, {
                              key: i,
                              children: (
                                <>
                                  <span className={classes.icon}>
                                    <IconSvg />
                                  </span>
                                  {child.props.children}
                                </>
                              ),
                            })
                          : null
                      )}
                    </ul>
                  );
                },
              }}
            />
            {feature.ctaLink ? (
              <Button
                variant='contained'
                size='large'
                className={classes.cta}
                href={feature.ctaLink}
                component='a'
                style={{ textDecoration: 'none' }}
              >
                {feature.ctaText}
              </Button>
            ) : feature.ctaText ? (
              <Button
                variant='contained'
                size='large'
                className={classes.cta}
                onClick={handleModalClick}
                style={{ textDecoration: 'none' }}
              >
                {feature.ctaText}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
