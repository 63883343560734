import React from 'react';
import { Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { EmailForm } from '../ContactForm/EmailForm';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
  homeHeroTop: {
    color: '#fff',
    fontWeight: '600',
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontFamily: 'Roboto, sans-serif',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.15rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  homeHeroHeading: {
    color: '#fff',
    textAlign: 'left',
    maxWidth: '730px',
    fontSize: '3.6rem',
    fontWeight: 700,
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.75rem',
      maxWidth: '630px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4rem',
      maxWidth: '590px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.8rem',
    },
  },
  homeHeroSubheading: {
    color: '#fff',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    fontSize: '1.55rem',
    margin: '0',
    marginTop: '1rem',
    lineHeight: '1.2',
    maxWidth: '510px',
    fontFamily: 'Roboto, sans-serif',
  },
  homeHeroEmail: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px',
    },
  },
  videoWrapper: {
    position: 'absolute',
    top: '95px',
    right: '90px',
    width: '628px',
    height: '525px',
    transform: 'scale(1.5)',
    zIndex: 0,
    overflow: 'hidden', // ensures no visual bleed
    [theme.breakpoints.down('lg')]: {
      right: '-90px',
    },
    [theme.breakpoints.down('md')]: {
      top: '0px',
      right: '-300px',
      transform: 'scale(1.3)',
    },
    [theme.breakpoints.down('sm')]: {
      right: '-30%',
      transform: 'scale(0.8)',
    },
  },
  clippedVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    clipPath: `path("M627.853 0H46.8525C-18.5303 254.004 -25.0495 375.465 89.3525 504.5L627.853 396.5V0Z")`,
    WebkitClipPath: `path("M627.853 0H46.8525C-18.5303 254.004 -25.0495 375.465 89.3525 504.5L627.853 396.5V0Z")`,
    position: 'absolute',
    top: '8px',
    left: '8px',
    zIndex: 1,
    transform: 'scale(0.97)', // slightly scale to prevent edge bleed
    transformOrigin: 'top left',
  },
  svgBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '628px',
    height: '525px',
    zIndex: 2,
    pointerEvents: 'none',
  },
}));

export const HomeHero = ({ heroData = {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isAboveMd = useMediaQuery(theme.breakpoints.up('md'));

  const { homeHeroVideo, videoImage, _rawHomeHeroContent } = heroData;

  const filteredContent = _rawHomeHeroContent?.filter(
    (block) =>
      block.children && block.children.some((child) => child.text.trim() !== '')
  ) || [];

  return (
    <>
      <Container style={{ position: 'relative', zIndex: 99 }}>
        <PortableText
          content={filteredContent}
          serializers={{
            h1: (props) => (
              <Typography variant="h2" className={classes.homeHeroHeading}>
                {props.children}
              </Typography>
            ),
            h4: (props) => (
              <Typography variant="h1" className={classes.homeHeroTop}>
                {props.children}
              </Typography>
            ),
            normal: (props) => (
              <Typography
                variant="body1"
                className={classes.homeHeroSubheading}
                component="p"
              >
                {props.children}
              </Typography>
            ),
            strong: (props) => (
              <strong style={{ color: '#63CDE5' }}>{props.children}</strong>
            ),
            default: (props) => <div>{props.children}</div>,
          }}
        />

        <div style={{ marginTop: '2rem' }} className={classes.homeHeroEmail}>
          <EmailForm />
        </div>
      </Container>

      {isAboveMd && homeHeroVideo && (
        <div className={classes.videoWrapper}>
          <video
            autoPlay
            muted
            loop
            playsInline
            poster={videoImage?.asset?.url || undefined}
            className={classes.clippedVideo}
          >
            <source src={homeHeroVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <svg
            className={classes.svgBorder}
            viewBox="-10 -10 648 545"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M627.853 0H46.8525C-18.5303 254.004 -25.0495 375.465 89.3525 504.5L627.853 396.5V0Z"
              stroke="#FFF"
              strokeWidth="15"
              fill="none"
            />
          </svg>
        </div>
      )}
    </>
  );
};
