import React, { useState, useEffect, useRef, useContext } from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { CtaCard } from '../../General/CtaCard';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#FFF',
    textAlign: 'center',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {},
  },
  box: {
    padding: theme.spacing(3),
    textAlign: 'center',
    padding: '0px',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: '2',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'flex-start',
      textAlign: 'left',
    },
  },
  box1: {
    padding: theme.spacing(3),
    textAlign: 'center',
    padding: '0px',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: '2',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '0 2rem',
    [theme.breakpoints.down('md')]: { justifyContent: 'center', margin: 0 },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'center',
      margin: 0,
    },
  },
  box2: {
    padding: theme.spacing(3),
    textAlign: 'center',
    padding: '0px',
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    position: 'relative',
    zIndex: '2',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0 2rem',
    [theme.breakpoints.down('md')]: { justifyContent: 'center', margin: 0 },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  boxCircle: {
    width: '90px',
    minWidth: '90px',
    height: '90px',
    borderRadius: '50%',
    background: 'transparent',
    // padding: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '3px solid #fff',
    margin: '0 10px 0 0',
  },
  boxH3: {
    fontSize: '4rem',
    margin: '0',
    padding: '0',
    fontFamily: '"Poppins", sans-serif',
    lineHeight: '1',
  },
  boxP: {
    fontSize: '1.35rem',
    fontWeight: '700',
    fontFamily: '"Poppins", sans-serif',
    margin: '0',
    padding: '0',
    lineHeight: '1.1',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
  },
  boxSVG: {},
  button: {
    background: '#FFF',
    color: theme.workwaveBlue,
    opacity: 1,
    '&:hover': {
      background: '#FFF',
      opacity: 0.85,
    },
  },
  icon: {
    color: '#FEE03E',
  },
  contentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

export const IntroStats = ({ header, stats, disclaimer, noStatsCta }) => {
  const classes = useStyles();
  const { handleModalClick } = useContext(FormModalContext);

  // Create state variables for each count
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  // Refs for each h3 element
  const countRef1 = useRef(null);
  const countRef2 = useRef(null);
  const countRef3 = useRef(null);

  // Helper function to animate the count up
  const animateValue = (start, end, duration, setter) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setter(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  useEffect(() => {
    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = entry.target;
          if (target === countRef1.current) {
            animateValue(0, stats[0].number, 1500, setCount1); // Count to 90%
          } else if (target === countRef2.current) {
            animateValue(0, stats[1].number, 2500, setCount2); // Count to 6M
          } else if (target === countRef3.current) {
            animateValue(0, stats[2].number, 3500, setCount3); // Count to 120B
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.5,
    });

    // Observe the h3 elements for each count
    if (countRef1.current) observer.observe(countRef1.current);
    if (countRef2.current) observer.observe(countRef2.current);
    if (countRef3.current) observer.observe(countRef3.current);

    return () => {
      // Cleanup the observer when the component unmounts
      if (countRef1.current) observer.unobserve(countRef1.current);
      if (countRef2.current) observer.unobserve(countRef2.current);
      if (countRef3.current) observer.unobserve(countRef3.current);
    };
  }, []);

  const countArray = [count1, count2, count3];
  const countRefArray = [countRef1, countRef2, countRef3];

  const med = useMediaQuery('(max-width: 960px)');
  return (
    <Container>
      <PortableText
        content={header}
        serializers={{
          h2: ({ children }) => (
            <Typography variant='h2' className={classes.header}>
              {children}
            </Typography>
          ),
          strong: ({ children }) => (
            <span style={{ color: '#63CDE5' }}>{children}</span>
          ),
        }}
      />

      {stats.length > 1 ? (
        <Grid container spacing={3} alignItems='center'>
          {stats.map((stat, index) => (
            <Grid item xs={12} md={stats.length === 3 ? 4 : 6} key={index}>
              <Paper
                className={
                  stats.length === 2 && index === 0
                    ? classes.box1
                    : stats.length === 2 && index === 1
                    ? classes.box2
                    : classes.box
                }
                style={{
                  height: '135px',
                  margin: !med && stats.length === 2 ? '0 6rem' : null,
                }}
              >
                <div className={classes.boxCircle}>
                  <FontAwesomeIcon
                    icon={['fas', stat.icon ?? 'fish']}
                    size='3x'
                    className={classes.icon}
                  />
                </div>
                <div className={classes.contentContainer}>
                  <h3 className={classes.boxH3} ref={countRefArray[index]}>
                    {stat.leftAlignUnit && stat.unit}
                    {countArray[index]}
                    {!stat.leftAlignUnit && stat.unit}
                  </h3>
                  <PortableText
                    content={stat._rawDescription}
                    serializers={{
                      normal: ({ children }) => (
                        <p className={classes.boxP}>{children}</p>
                      ),
                    }}
                  />
                </div>
              </Paper>
            </Grid>
          ))}

          {disclaimer && (
            <Grid item xs={12}>
              <Typography
                variant='body1'
                style={{
                  color: '#FFF',
                  fontStyle: 'italic',
                  marginTop: '2rem',
                  textAlign: 'center',
                }}
              >
                {disclaimer}
              </Typography>
            </Grid>
          )}
        </Grid>
      ) : (
        <CtaCard
          background={noStatsCta.ctaBackground}
          header={noStatsCta._rawCtaBlockHeader}
          subheader={noStatsCta.ctaSubheader}
          ctaText={noStatsCta.ctaText}
          noStats
        />
      )}
    </Container>
  );
};
